import React, { useState } from 'react';
import '../App.css';


const OptionButtons = props => {
	const [selected, setSelected] = useState(0);
	const { labelText, onChange, options } = props;

	const changeInputValue = (index, value) => {
		setSelected(index);
		onChange(value);
	}

	return (
		<div className="simpleInput">
			<label className="simpleLabel">{labelText}</label>
			{
				options &&
				options.map((elem, i) => (
					<button
						key={i}
						onClick={() => changeInputValue(i + 1, elem.value)}
						className={selected == i + 1 ? "optionButtonSelected" : "optionButton"}
					>
						{elem.text}
					</button>
				))
			}
		</div>
	);
}

export default OptionButtons;