import React, { useRef, useEffect, useState } from 'react';
import '../App.css';

const SelectInput = props => {
    const wrapperRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Selecciona una opción");
    const [extraPrice, setExtraPrice] = useState(0);
    const {
        labelText,
        options,
        onChange,
        required,
        extraPriceValue,
        handleExtraAmountChange
    } = props;

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        setSelectedOption("Selecciona una opción");
    }, [JSON.stringify(options)]);

    const handleInputChange = elem => {
        onChange(elem.value);
        setSelectedOption(elem.text);
        setIsOpen(false);
        handleExtraPriceChange(elem);
    }

    const handleExtraPriceChange = elem => {
        if(handleExtraAmountChange) {
            if(extraPriceValue && elem.value != null) {
                setExtraPrice(extraPriceValue);
                handleExtraAmountChange(labelText, extraPriceValue);
            } else {
                setExtraPrice(0);
                handleExtraAmountChange(labelText, 0);
            }
        }
    }

    const handleClickOutside = event => {
        if(wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    }

    return (
        <div className="simpleInput">
            <label className="simpleLabel">{labelText}</label>
            <div className="selectBar" onClick={() => setIsOpen(true)}>
                {selectedOption}
            </div>
            {
                isOpen &&
                <div className="selectMenu" ref={wrapperRef}>
                    {
                        required === false &&
                        <div key={'-1'} onClick={() => handleInputChange({ value: null, text: 'Ninguno' })}>Ninguno</div>
                    }
                    {
                        options &&
                        options.map((elem, i) => {
                            return <div key={i} onClick={() => handleInputChange(elem)}>{elem.text}</div>;
                        })
                    }
                </div>
            }
            {
                extraPrice != 0 &&
                <p className="msgExtra">{`Suma $${extraPrice} al precio total`}</p>
            }
        </div>
    );
}

export default SelectInput;