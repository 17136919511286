import React from 'react';
import '../App.css';

const SimpleButton = props => {

	const { buttonText, isHidden, onClick, stageToGo, disabled } = props;

	return (
		<div className={isHidden ? "hidden" : "simpleButtonContainer"}>
			<button
				onClick={e => {
					if(!disabled)
						onClick();
				}}
				className={disabled ? "disabledButton" : "simpleButton"}
			>
				{buttonText}
			</button>
		</div>
	);
}

export default SimpleButton;