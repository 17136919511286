import React from 'react';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footerColumn">
                <h4>Contacto</h4>
                <p id="phoneNumberFooter"></p>
                <p id="emailFooter"></p>
            </div>
            <div className="footerColumn">
                <h4>Información</h4>
                <p id="additionalInformationFooter"></p>
            </div>
        </div>
    );
}

export default Footer;