import React, { useState, useContext, useEffect } from 'react';
import SimpleInput from './SimpleInput.js';
import SimpleButton from './SimpleButton.js';
import DropdownInput from './DropdownInput';
import SelectInput from './SelectInput';
import PhoneInput from './PhoneInput';
import { DataContext } from '../commons/DataContext.js';
const params = new URLSearchParams(window.location.search);
const hash = params.get('hash');


const checkPlans = plans => {
    let existeMensual = false;
    let existeUnico = false;
    for (let plan of plans) {
        if (plan.defaultPeriods == 1) existeUnico = true;
        else existeMensual = true;
    }

    return { existeMensual, existeUnico };
}

const getTotalAmount = inputValues => {
    if (Object.values(inputValues.extraAmount) && Object.values(inputValues.extraAmount).length) {
        const reducer = (accumulator, currentValue) => accumulator + currentValue;
        const extraPrice = Object.values(inputValues.extraAmount).reduce(reducer);
        return extraPrice + inputValues.amount;
    }
    return inputValues.amount;
}

const renderPageCustomFields = (
    data,
    customFields,
    handleMetadataChange,
    handleExtraAmountChange,
    metadata
) => {
    if (!customFields.length) return;
    return customFields.map(field => {
        // El custom field viene en el objeto root si es hash
        // Y un nivel mas adentro cuando es page
        if (!field.customField)
            field.customField = field;
        if (field.customField.type == "TEXT" || field.customField.type == "NUMBER")
            return <SimpleInput
                labelText={field.customField.label}
                placeholderText=""
                type={field.customField.type}
                onChange={handleMetadataChange}
                key={field.id}
                name={field.id}
                organizationId={data.organizationId}
                extraPriceValue={field.customField.amount}
                handleExtraAmountChange={handleExtraAmountChange}
                value={metadata[field.id]}
            />
        if (field.customField.type == "SELECT")
            return <SelectInput
                labelText={field.customField.label}
                options={field.values.map(elem => ({ text: elem, value: elem }))}
                required={field.customField.required}
                key={field.id}
                name={field.id}
                extraPriceValue={field.customField.amount}
                handleExtraAmountChange={handleExtraAmountChange}
                onChange={
                    value => {
                        handleMetadataChange(null, field.id, value)
                    }
                }
            />
    });
}


const PaymentDataForm = props => {
    const [isButtonsDisabled, setIsButtonDisabled] = useState(false);
    const [isCustomAmount, setIsCustomAmount] = useState(false);
    const [showsRecurrenceButtons, setShowsRecurrenceButtons] = useState(true);
    const [showsPaymentMethodButtons, setShowsPaymentMethodButtons] = useState(true);
    const { inputValues, paymentMethods } = useContext(DataContext);
    const {
        plans,
        options,
        metadata,
        setOptions,
        setProductHash,
        setPlansOptions,
        handleInputChange,
        handleMetadataChange,
        handleExtraAmountChange,
        irAlCheckout,
        setIsChecked,
        isChecked,
        data,
        errorText,
        acceptTerms,
        productHash,
        isDasMas,
        paymentExpiration,
        ahora12Enabled,
        restorePlansData,
        filterAhora12Plans
    } = props;

    useEffect(() => {
        const plansRecurrence = checkPlans(plans);
        if (!plansRecurrence.existeMensual || !plansRecurrence.existeUnico) {
            setShowsRecurrenceButtons(false);
            //Simulando click en mensual:
            setPlansOptions(plans, setOptions, plansRecurrence.existeMensual, "", handleInputChange, params.get('phash'), setProductHash);
            setIsChecked(true);
        }
        if (!paymentMethods.cash) {
            //Simulando click en tarjeta:
            handleInputChange(null, "paymentMethod", "CHARGE");
            setShowsPaymentMethodButtons(false);
        }
        if (!paymentMethods.credit_card && !paymentMethods.debit_card) {
            //Simulando click en efectivo:
            handleInputChange(null, "paymentMethod", "SEND_INVOICE");
            setShowsPaymentMethodButtons(false);
        }
    }, []);

    const handleCustomAmount = (val) => {
        setIsCustomAmount(val);
        handleInputChange(null, "planId", null);
    }

    const handleAmountChange = amount => {
        amount = amount.substring(1).replace(/[^\dA-Z]/g, '');
        handleInputChange(null, "amount", amount);
    }

    const handleEmailChange = value => {
        handleInputChange(null, "edit", true);
        handleInputChange(null, "email", value);
    }


    return (
        <div>
            <h3 className="midHeader">Datos sobre tu pago:</h3>
            {
                data && showsPaymentMethodButtons &&
                <div>
                    <div className="simpleInput">

                        <label className="simpleLabel">Forma de pago</label>
                        <label onClick={() => { handleInputChange(null, "paymentMethod", "SEND_INVOICE");restorePlansData(); }} className="radioButtonContainer">Enviarme un link de pago todos los meses
                            <input type="radio" name="radio2" />
                            <span className="checkmark"></span>
                        </label>
                        <label onClick={() => { handleInputChange(null, "paymentMethod", "CHARGE");
                                                restorePlansData(); }
                                                } className="radioButtonContainer">Débito automático con tarjeta
                            <input type="radio" name="radio2" />
                            <span className="checkmark"></span>
                        </label>

                        {
                            ahora12Enabled && (
                                <>
                                    <br />
                                    <label onClick={() => {
                                        handleInputChange(null, "paymentMethod", "AHORA12");
                                        filterAhora12Plans();;
                                    }} className="radioButtonContainer">Pagar con Ahora12
                                    <input type="radio" name="radio2" />
                                        <span className="checkmark"></span>
                                    </label>
                                </>
                            )
                        }

                    </div>
                    {
                        inputValues.paymentMethod == "CHARGE" &&
                        <div className="paymentClarificationDiv">
                            <span className="paymentClarificationSpan">i</span>
                            <p className="paymentClarification">
                                Si eliges un plan con cuotas, se cobrarán automaticamente, no es necesario que realices el pago cada mes.
                            </p>
                        </div>
                    }

                </div>
            }
            {
                isDasMas &&
                <SimpleInput
                    labelText={"Email"}
                    extraText="A este mail te enviaremos los comprobantes de pago"
                    name={"email"}
                    value={inputValues.email}
                    placeholderText={"YourName@example.com"}
                    onChange={e => handleEmailChange(e.target.value)}
                />
            }
            {
                !isDasMas &&
                <SimpleInput
                    labelText={"DNI"}
                    placeholderText="XXXXXXXX"
                    type="number"
                    name="dni"
                    onChange={handleInputChange}
                />
            }
            {
                renderPageCustomFields(
                    data,
                    inputValues.customFields,
                    handleMetadataChange,
                    handleExtraAmountChange,
                    metadata
                )
            }
            {
                !isDasMas &&
                <PhoneInput
                    labelText={"Teléfono"}
                    name="telefono"
                    handleInputChange={handleInputChange}
                />
            }
            {
                data.template != "NGO" && !productHash &&
                <SelectInput
                    labelText={
                        isDasMas ?
                            "Elegí tu prenda" :
                            "Elegí el producto"
                    }
                    options={options}
                    onChange={
                        value => {
                            handleInputChange(null, "amount", value.price)
                            handleInputChange(null, "planId", value.id)
                        }
                    }
                />
            }
            {
                !productHash && data.template == "NGO" &&
                <DropdownInput
                    options={options}
                    labelText={"Monto a pagar"}
                    name={"amount"}
                    setIsCustomAmount={handleCustomAmount}
                    onChange={handleInputChange}
                    esIncutex={hash == 'lZoR1YZRd1'}
                />
            }
            {
                isCustomAmount &&
                <SimpleInput
                    labelText={"Monto"}
                    value={'$' + inputValues.amount}
                    onChange={(e) => handleAmountChange(e.target.value)}
                />
            }
            <p id="errorMsg">{errorText}</p>
            {
                //HARDCODEADO
                data && data.template != "NGO" && data.title == "Hey You - Buzos para Egresados" &&
                <div className="terms">
                    <input type="checkbox" onClick={acceptTerms}></input>
                    <p>
                        Acepto la compra de los artículos indicados en la forma de pago elegida, como así también los términos generales estipulados en el contrato grupal. Entiendo la obligación de pago que estoy tomando con la empresa y soy responsable de las acciones que puedan generarse por el no cumplimiento.
                    </p>
                </div>
            }
            {
                paymentExpiration.expired && inputValues.amount && isDasMas &&
                <div className="paymentClarificationDiv">
                    <span className="expirationSpan">i</span>
                    <p className="paymentClarification">
                        La primera cuota venció el {paymentExpiration.date}, por lo que pagarás con un recargo de {'$' + (getTotalAmount(inputValues) * (paymentExpiration.percentage / 100)).toFixed(2)}
                    </p>
                </div>
            }
            <SimpleButton
                disabled={isButtonsDisabled}
                onClick={() => {
                    irAlCheckout(data && data.template === "NGO", () => setIsButtonDisabled(true));
                }}
                buttonText={"Ir al checkout"}
            />
        </div>
    );
}

export default PaymentDataForm;