import React from 'react';
import SimpleInput from './SimpleInput.js';


const CardHolderData = props => {
    const { fname, lname, dni, handleInputChange } = props;

    return (
        <div>
            <SimpleInput
                labelText={"Nombre del titular"}
                value={fname}
                name="fname"
                onChange={e => handleInputChange(null, "fname", e.target.value)}
            />
            <SimpleInput
                labelText={"Apellido del titular"}
                value={lname}
                name="lname"
                onChange={e => handleInputChange(null, "lname", e.target.value)}
            />
            <SimpleInput
                labelText={"DNI"}
                value={dni}
                name="dni"
                onChange={e => handleInputChange(null, "dni", e.target.value)}
            />
        </div>
    );
}

export default CardHolderData;