import axios from 'axios';
import { paymentDto, collectPaymentDto } from './PaymentDto.js'

const zenrise = new window.Zenrise.Sdk(
    process.env.REACT_APP_BASE_SDK_ENV,
    Number(process.env.REACT_APP_BASE_SDK_KEY)
);
const params = new URLSearchParams(window.location.search);
const api = process.env.REACT_APP_BASE_URL_API;


export const getUserStyles = async () => {
    return axios({
        method: 'GET',
        url: `${api}/v1/subscriber/${params.get('hash')}`,
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(r => {
        return r.data;
    }).catch(e => {
        return e.response.status;
    });
}


export const getPageStyles = async () => {
    return axios({
        method: 'GET',
        url: `${api}/v1/subscription/page/hash/${params.get('page')}`,
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(r => {
        return r.data;
    }).catch(e => {
        return e.response.status;
    });
}


export const getUserPlans = async () => {
    return axios({
        method: 'GET',
        url: `${api}/v1/subscriber/plans/${params.get('hash')}`,
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response.status;
    });
}


export const getPlan = async () => {
    return axios({
        method: 'GET',
        url: `${api}/v1/plan/hash/${params.get('phash')}/${params.get('hash')}`,
        headers: {
            'Content-Type': 'application/json'
        }
    });
}


export const getContactInfo = async () => {
    return axios({
        method: 'GET',
        url: `${api}/v1/subscriber/contact`,
        headers: {
            'Content-Type': 'application/json'
        },
        params: {
            organizationHash: params.get('hash'),
            contactHash: params.get('chash')
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response.status;
    });
}


export const getInstallments = async (amount, cardNumber) => {
    const firstFourDigits = cardNumber.substring(0, 4);
    return await zenrise.resolveInstallmentsPerCardNumberAndAmount(firstFourDigits, amount);
}


export const sendPaymentRequest = async (paymentValues, inputValues, metadata, orgHash, pageId=null) => {
    try {
        const tokenDto = await getToken(paymentValues, inputValues);
        const paymentObj = paymentDto(inputValues, tokenDto, paymentValues, metadata, pageId);
        const organizationHash = orgHash ? orgHash : params.get('hash');

        return axios({
            method: 'POST',
            url: `${api}/v1/subscriber/add-subscription/${organizationHash}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: paymentObj
        }).then(r => {
            return r;
        }).catch(e => {
            return e;
        });

    } catch (e) {
        return {
            status: 400
        }
    }
}


export const sendCollectPaymentRequest = (inputValues, metadata, orgHash, pageId=null) => {
    try {
        const paymentObj = collectPaymentDto(inputValues, metadata, pageId);
        const organizationHash = orgHash ? orgHash : params.get('hash');
        
        return axios({
            method: 'POST',
            url: `${api}/v1/subscriber/add-subscription/${organizationHash}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: paymentObj
        }).then(r => {
            return r;
        }).catch(e => {
            return e;
        });

    } catch (e) {
        return {
            status: 400
        }
    }
}


const getToken = async paymentValues => {
    const cardData = getCardData(paymentValues);
    const data = await zenrise.getCardToken(cardData);
    return data;
}


const getCardData = paymentValues => {
    const { number, cvc, expiry } = paymentValues;
    const date = expiry.split('/');
    return ({
        card_number: number.replace(/\s/g, ''),
        card_expiration_month: date[0],
        card_expiration_year: date[1],
        card_holder_name: paymentValues.fname + ' ' + paymentValues.lname,
        card_holder_identification: {
            type: 'dni',
            number: paymentValues.dni
        }
    })
};