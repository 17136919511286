import React, { useRef, useEffect, useState } from 'react';
import '../App.css';

const Autocomplete = props => {
    const wrapperRef = useRef(null);
    const inputRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedOption, setSelectedOption] = useState("Selecciona una opción");
    const [extraPrice, setExtraPrice] = useState(0);
    const {
        labelText,
        options,
        onChange,
        required,
        extraPriceValue,
        handleExtraAmountChange
    } = props;

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        setSelectedOption("Selecciona una opción");
    }, [JSON.stringify(options)]);

    const handleInputChange = elem => {
        setSearch('');
        onChange(elem.value);
        setSelectedOption(elem.text);
        setIsOpen(false);
        handleExtraPriceChange(elem);
    }

    const handleExtraPriceChange = elem => {
        if(handleExtraAmountChange) {
            if(extraPriceValue && elem.value != null) {
                setExtraPrice(extraPriceValue);
                handleExtraAmountChange(labelText, extraPriceValue);
            } else {
                setExtraPrice(0);
                handleExtraAmountChange(labelText, 0);
            }
        }
    }

    const compareSearch = studentObject => {
        const re = new RegExp(search.toLowerCase(), 'g');
        if(studentObject.value.fullName.toLowerCase().match(re)) {
            return true;
        }
        if(search.length < 2) return true;
    }

    const handleClickOutside = event => {
        if(
            wrapperRef.current &&
            !wrapperRef.current.contains(event.target) &&
            inputRef.current &&
            !inputRef.current.contains(event.target)
        ) {
            setIsOpen(false);
            setSearch('');
        }
    }

    const handleSearch = text => {
        setSearch(text);
    }

    const openOptions = () => {
        setIsOpen(true);
        setTimeout(() => {
            inputRef.current.focus();
        });
    }

    const handleKeyDown = (event) => {
        if(event.key === 'Enter') {
            const results = options.filter(compareSearch);
            if(results.length == 1)
                handleInputChange(results[0]);
        }
    }

    const filterOptions = () => {
        const results = options.filter(compareSearch).map((elem, i) => {
            return <div key={i} onClick={() => handleInputChange(elem)}>{elem.text}</div>;
        })

        if(results.length == 0) return <div className="noResultsText">Sin resultados</div>;;

        return results;
    }

    return (
        <div className="simpleInput">
            <label className="simpleLabel">{labelText}</label>
            {
                isOpen ?
                    <input
                        ref={inputRef}
                        className="autocompleteInputBar"
                        placeholder="Escribe para buscar"
                        value={search}
                        onChange={e => handleSearch(e.target.value)}
                        onKeyDown={handleKeyDown}
                    /> :
                    <div className="selectBar" onClick={openOptions}>
                        {isOpen ? 'Escribe para buscar' : selectedOption}
                    </div>
            }
            {
                isOpen &&
                <div className="selectMenu" ref={wrapperRef}>
                    {
                        required === false &&
                        <div key={'-1'} onClick={() => handleInputChange({ value: null, text: 'Ninguno' })}>Ninguno</div>
                    }
                    {
                        options &&
                        filterOptions()
                    }
                </div>
            }
            {
                extraPrice != 0 &&
                <p className="msgExtra">{`Suma $${extraPrice} al precio total`}</p>
            }
        </div>
    );
}

export default Autocomplete;