import React from 'react';
import '../App.css';

const ExpiryWall = ({ data }) => {

    return (
        <div className="expiryWallContainer">
            <h1>Página vencida</h1>
            <p>Ésta página venció el día {data.pageExpirationDate.split('-').join('/')} y ya no admite suscripciones</p>
        </div>
    );
}

export default ExpiryWall;