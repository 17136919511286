import React, { useState, useRef } from 'react';
import '../App.css';


const DropdownInput = props => {
	const [selected, setSelected] = useState(0);
	const { labelText, onChange, options, setIsCustomAmount, esIncutex } = props;

	const changeInputValue = (index, value) => {
		if(index === options.length + 1) {
			setIsCustomAmount(true);
			setSelected(index);
			onChange("fakeEvent", "planId", null);
			return true;
		}
		setIsCustomAmount(false);
		setSelected(index);
		onChange("fakeEvent", "planId", value.id);
		onChange("fakeEvent", "amount", value.price);
	}

	return (
		<div className="simpleInput">
			<label className="simpleLabel">{labelText}</label>
			{
				options &&
				options.map((elem, i) => (
					<button
						key={i}
						onClick={() => changeInputValue(i + 1, elem.value)}
						className={selected == i + 1 ? "optionButtonSelected" : "optionButton"}
					>
						{elem.text}
					</button>
				))
			}
			{
				!esIncutex &&
				<button
					key={options.length}
					onClick={() => changeInputValue(options.length + 1, null)}
					className={selected == options.length + 1 ? "optionButtonSelected" : "optionButton"}
				>
					Otro
				</button>
			}
		</div>
	);
}

export default DropdownInput;