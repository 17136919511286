import React from 'react';

const Snackbar = ({ onClick, text }) => {

    return (
        <div className="snackbarContainer">
            <div className="snackbar">
                <p>{text}</p>
                <button onClick={onClick}>OK</button>
            </div>
        </div>
    );
}

export default Snackbar;