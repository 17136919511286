import React from 'react';
import '../App.css';

const CardPersonalInfo = props => {

	const { labelText, fname, lname, onClick } = props;

	return (
		<div className="simpleInput">
			<label className="simpleLabel">{labelText}</label>
            <p className="cardOwner">{fname + ' ' + lname}</p>
            <button id="editCardOwner" className="editCardOwner" onClick={() => onClick(true)}>Editar</button>
		</div>
	);
}

export default CardPersonalInfo;