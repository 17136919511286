import React from 'react';
import SimpleButton from './SimpleButton.js';
import '../App.css';

const ContactWall = props => {
	const { nombre, link } = props;

	return (
		<div className="contactWallContainer">
			<h1>Hola {nombre}!</h1>
            Parece que ya elegiste tu prenda.
            Puedes ingresar al siguiente link para gestionar o pagar las mismas:
            <SimpleButton
                buttonText={"Ir a la página de autogestión"}
                onClick={() => window.open(link, "_blanck")}
            />
		</div>
	);
}

export default ContactWall;