import React from 'react';
import '../App.css';

const DateInput = props => {
    const { labelText, setCvc, handleDateChange, isNgo } = props;

    const handleCvcChange = e => {
        if(e.target.value.length > 3)
            e.target.value = e.target.value.substr(0, 3);
        setCvc(null, "cvc", e.target.value)
    }

    return (
        <div>
            <div className="expirationInput">
                <label className="simpleLabel">{labelText}</label>
                <input
                    type="text"
                    name="expiry"
                    onChange={handleDateChange}
                    placeholder="MM/AA"
                    className="shortInputBar"
                    max="9999"
                >
                </input>
            </div>
            {/* {
                !isNgo &&
                <div className="cvcInput">
                    <label className="simpleLabel">CVC</label>
                    <input 
                        type="number"
                        name="cvc" 
                        onChange={handleCvcChange}
                        placeholder="XXX"
                        className="shortInputBar"
                        max="999"
                    >
                    </input>
                </div>   
            }                      */}
        </div>
    );
}

export default DateInput;