import React, { useContext, useEffect, useState } from 'react';
import SimpleInput from './SimpleInput.js'
import Autocomplete from './Autocomplete.js'
import SimpleButton from './SimpleButton.js'
import { DataContext } from '../commons/DataContext.js';


const handleSelectorChange = (
    contact,
    handleInputChange,
    handleContinueButtonClick,
    setIsContactSubscribed,
    groupId,
    setContactHasSubscribedToThisPage,
    data
) => {
    const hasSubscriptions = contact.subscriptions.length > 0;
    if (hasSubscriptions) {
        const pageFromSubscriptions = contact.subscriptions.map(({ pageId }) => pageId);
        setContactHasSubscribedToThisPage(pageFromSubscriptions.includes(data.pageId));
    } else setContactHasSubscribedToThisPage(false);
    setIsContactSubscribed(hasSubscriptions);

    // Not sure why this works
    handleInputChange(null, "nombre", contact.firstName);
    handleInputChange(null, "apellido", contact.lastName);
    handleInputChange(null, "email", contact.email);
    handleInputChange(null, "contactId", contact.id);
    handleInputChange(null, "contactLink", contact.selfManagementLink)
    handleInputChange(null, "groupId", groupId);
    handleContinueButtonClick(true, false);
}


const PersonalDataForm = props => {
    const {
        setContactHasSubscribedToThisPage,
        handleInputChange,
        handleContinueButtonClick,
        setIsContactSubscribed,
        stage,
        organizationId,
        contactGroup,
        data
    } = props;
    const { inputValues } = useContext(DataContext);
    const [template, setTemplate] = useState('Standard');
    const [showsEmail, setShowsEmail] = useState(true);

    useEffect(() => {
        if(
            //dasmas 
            organizationId == 154 &&
            contactGroup?.contact?.length > 0
        ) {
            setTemplate('Dasmas');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {
                template == 'Standard' &&
                <div>
                    <SimpleInput
                        labelText={"Nombre"}
                        value={inputValues.nombre}
                        name={"nombre"}
                        placeholderText={"Paul"}
                        onChange={handleInputChange}
                    />
                    <SimpleInput
                        labelText={"Apellido"}
                        value={inputValues.apellido}
                        name={"apellido"}
                        placeholderText={"McCartney"}
                        onChange={handleInputChange}
                    />
                    {
                        showsEmail &&
                        <SimpleInput
                            labelText={"Email"}
                            name={"email"}
                            value={inputValues.email}
                            placeholderText={"YourName@example.com"}
                            onChange={handleInputChange}
                        />
                    }
                    <SimpleInput
                        labelText={"DNI"}
                        name={"contactDni"}
                        value={inputValues.contactDni}
                        placeholderText={"XXXXXXXX"}
                        onChange={handleInputChange}
                    />
                    <SimpleButton
                        buttonText={"Continuar"}
                        isHidden={stage !== 2 ? "hidden" : ""}
                        onClick={() => {
                            if(contactGroup) {
                                handleInputChange(null, "groupId", contactGroup.id);
                            }
                            handleContinueButtonClick();
                        }}
                    />
                </div>
            }
            {
                template == 'Dasmas' &&
                <div style={{ position: 'relative' }}>
                    <Autocomplete
                        labelText={"Alumno"}
                        options={contactGroup.contact.map(contact => ({ text: contact.fullName, value: contact }))}
                        name={"nombre"}
                        onChange={handleInputChange}
                        onChange={
                            contact => {
                                handleSelectorChange(
                                    contact,
                                    handleInputChange,
                                    handleContinueButtonClick,
                                    setIsContactSubscribed,
                                    contactGroup.id,
                                    setContactHasSubscribedToThisPage,
                                    data
                                );
                            }
                        }
                    />
                   
                   { 
                    <p
                        className="addStudentButton"
                        onClick={() => {
                            handleInputChange(null, "nombre", "");
                            handleInputChange(null, "apellido", "");
                            handleInputChange(null, "contactId", null);
                            setIsContactSubscribed(false);
                            setTemplate('Standard');
                            setShowsEmail(false);
                        }}
                    >
                        ¿No apareces en el listado? - Agregar alumno
                    </p>
                    }

                    {/* {
                        isSelected &&
                        <p
                            className="editNameButton"
                            onClick={() => {
                                setTemplate('Standard');
                                handleInputChange(null, "edit", true);
                            }}
                        >
                            	✎ Editar nombre
                        </p>
                    } */}
                </div>
            }
        </>
    );
}

export default PersonalDataForm;