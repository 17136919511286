import { getUserStyles, getPageStyles } from "./Services";
const params = new URLSearchParams(window.location.search);
const hash = params.get('hash');
const page = params.get('page');

export const loader = (show) => {
	if(show) {
		document.querySelector('.loaderContainer').style.opacity = '1';
		setTimeout(function () {
			document.querySelector('.loaderContainer').style.display = 'block';
		}, 1000);
		return;
	}

	document.querySelector('.loaderContainer').style.opacity = '0';
	setTimeout(function () {
		if(document.querySelector('.loaderContainer'))
			document.querySelector('.loaderContainer').style.display = 'none';
	}, 1000);
}


export const personalizeStyle = async prevData => {
	const noHash = hash && hash.length < 10 || !hash;
	const noPage = page && page.length < 10 || !page;
	if(noHash && noPage) {
		window.location.href = "http://zenrise.io";
	}
	const h1s = document.getElementsByTagName("h1");
	const buttons = document.getElementsByClassName("simpleButton");
	const title = document.getElementById("pageTitle");
	const subtitle = document.getElementById("pageSubtitle");
	const image = document.getElementById("leftPanel");
	const editOwner = document.getElementById("editCardOwner");
	const footer = {
		info: document.getElementById("additionalInformationFooter"),
		phone: document.getElementById("phoneNumberFooter"),
		email: document.getElementById("emailFooter")
	}
	let data = null;

	if(prevData == null) {
		// alert(prevData)
		if(page) {
			const res = await getPageStyles();
			data = res.configuration;
			data.pageId = res.id
			data.orgHash = res.orgHash;
			data.plans = res.plans;
			data.customFields = res.customFieldsInstances;
			data.organizationId = res.organizationId;
			data.contactGroup = res.contactGroup;
			data.ahora12Enable = res.ahora12Enable;
		} else {
			data = await getUserStyles();
		}
	} else {
		data = prevData;
	}
	
	if(title) {
		title.innerHTML = data.title;
		subtitle.innerHTML = data.subTitle;
	}

	// si es cataldro y no estamos viendo un solo plan quiere recibir donaciones asi que le cambiamos el titulo
	if(data.contactEmail && data.contactEmail == "cataladro@gmail.com" && !params.get('phash')){
		subtitle.innerHTML = "Hace tu donación para los comedores";
	}


	if(editOwner) {
		editOwner.style.color = data.mainColor;
		editOwner.style.borderColor = data.mainColor;
	}
	if(data.mainImage) {
		image.style.backgroundImage = `url("${data.mainImage}")`;
	}
	for(let i = 0; i < h1s.length; i++) {
		h1s[i].style.color = data.mainColor;
	}
	for(let i = 0; i < buttons.length; i++) {
		buttons[i].style.background = data.mainColor === '#BBB6B3'
			? '#333'
			: data.mainColor;
	}
	footer.phone.innerHTML = data.contactPhone;
	footer.email.innerHTML = data.contactEmail;
	footer.info.innerHTML = data.additionalContent;

	//Avoid flashes:
	loader(false);
	return data;

}