import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DataContext } from './commons/DataContext';
import PersonalDataForm from './components/PersonalDataForm.js';
import SubscriptionWall from './components/SubscriptionWall.js';
import PaymentDataForm from './components/PaymentDataForm.js';
import Checkout from './components/Checkout.js';
import ContactWall from './components/ContactWall.js';
import Footer from './components/Footer.js';
import Success from './components/Success';
import { personalizeStyle } from './commons/personalizeStyle.js';
import Loader from './components/Loader';
import Snackbar from './components/Snackbar';
import ExpiryWall from './components/ExpiryWall';
import { getPlan, sendCollectPaymentRequest, getContactInfo, getUserPlans } from './commons/Services.js';
import './App.css';
const params = new URLSearchParams(window.location.search);


const validateEmail = email => {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}


const checkPaymentForm = (inputValues, setErrorText, metadata) => {
	let isCorrect = true;
	if (inputValues.dni < 900000 || !inputValues.dni) {
		const input = document.querySelector('input[name="dni"]');
		if (input) {
			isCorrect = false;
			input.classList.add("inputBarRed");
		}
	}
	if (inputValues.phoneNumber < 9999 || !inputValues.phoneNumber) {
		const input = document.querySelector('input[name="phoneNumber"]');
		if (input) {
			isCorrect = false;
			input.classList.add("inputBarRed");
		}
	}
	if (inputValues.areaCode < 1 || !inputValues.areaCode) {
		const input = document.querySelector('input[name="areaCode"]');
		if (input) {
			isCorrect = false;
			input.classList.add("inputBarRed");
		}
	}
	if (!(inputValues.amount > 49)) {
		isCorrect = false;
		setErrorText("Elige un monto a pagar")
		setTimeout(() => {
			setErrorText("");
		}, 4000)
	}
	if (!checkPageMetadataValues(inputValues, metadata)) {
		isCorrect = false;
		setErrorText("Debes completar todos los campos")
		setTimeout(() => {
			setErrorText("");
		}, 4000)
	}

	return isCorrect;
}


const checkPageMetadataValues = (inputValues, metadata) => {
	const customFields = inputValues.customFields;
	let isMetadataCorrect = true;

	if (customFields) {
		for (const field of customFields) {
			if (!metadata[field.id] && field.customField.required) {
				isMetadataCorrect = false;
			}
		}
	}

	return isMetadataCorrect;
}


const checkPersonalForm = inputValues => {
	const nombre = inputValues.nombre ? inputValues.nombre : "";
	const apellido = inputValues.apellido ? inputValues.apellido : "";
	const email = inputValues.email ? inputValues.email : "";
	let isCorrect = true;

	if (nombre.length < 1) {
		const elem = document.querySelector('input[name="nombre"]');
		if (elem) {
			isCorrect = false;
			elem.classList.add("inputBarRed");
		}
	}
	if (apellido.length < 2) {
		const elem = document.querySelector('input[name="apellido"]');
		if (elem) {
			isCorrect = false;
			elem.classList.add("inputBarRed");
		}
	}
	if (!validateEmail(email)) {
		const elem = document.querySelector('input[name="email"]');
		if (elem) {
			isCorrect = false;
			elem.classList.add("inputBarRed");
		}
	}

	return isCorrect;
}


const selectProductFromHash = (opt, setOptions, handleInputChange, productHash, setProductHash) => {
	const options = opt.filter(elem => {
		return productHash == elem.hash;
	}).map(elem => {
		const value = {}
		value.price = elem.price;
		value.id = elem.id;
		handleInputChange(null, "amount", value.price)
		handleInputChange(null, "planId", value.id)
		const text = elem.name + " $" + elem.price;
		return { text, value };
	});
	setProductHash(productHash);
	setOptions(options);
}


const getPlanText = (elem, template) => {
	// Nombre de plan para NGO:
	if (template === "NGO") {
		let text = "$" + elem.price;
		if (elem.description) {
			text += " - " + elem.description;
		}
		return text;
	}

	if(elem.ahora12Enabled){
		let text = elem.name + " $" + elem.price;
		return text;
	}

	// Nombre de plan para Standard 1 cuota:
	if (elem.defaultPeriods == 1) {
		let text = elem.name + " $" + elem.price;
		return text;
	}
	// Nombre de plan para Standard n cuotas:
	if (elem.defaultPeriods) {
		let text = elem.name + " " + elem.defaultPeriods + " cuotas de  $" + elem.price;
		return text;
	}
	// Nombre de plan para Standard sin fecha de finalización:
	let text = elem.name + " cobro mensual de $" + elem.price;
	return text;
}


const setPlansOptions = (opt, setOptions, esRecurrencia, template, handleInputChange, productHash = null, setProductHash = null,
	filterAhora12Plans = false) => {
	if (productHash) {
		selectProductFromHash(opt, setOptions, handleInputChange, productHash, setProductHash);
		return;
	}
	handleInputChange(null, "recurrencia", esRecurrencia ^ 1);
	const options = opt.filter(elem => {

		if (filterAhora12Plans) {
			if (elem.ahora12Enabled) {
				return true;
			}
		}else{
			if (elem.ahora12Enabled) {
				return false;
			}
		}
		
		if (esRecurrencia) {
			return elem.defaultPeriods != 1;
		}
		return elem.defaultPeriods == 1;
	}).map(elem => {
		const value = {}
		const text = getPlanText(elem, template);
		value.price = elem.price;
		value.id = elem.id;
		return { value, text };
	});
	setOptions(options);
}


const setPaymentMethodsObject = (array, setPaymentMethods) => {
	const paymentMethods = {
		cash: false,
		credit_card: false,
		debit_card: false,
		direct_debit: false
	}

	if (array && array.length > 0) {
		for (let elem of array) {
			if (elem == 'credit-card') paymentMethods.credit_card = true;
			if (elem == 'debit-card') paymentMethods.debit_card = true;
			if (elem == 'cash') paymentMethods.cash = true;
			if (elem == 'direct-debit') paymentMethods.direct_debit = true;
		}
	} else {
		paymentMethods.debit_card = true;
		paymentMethods.credit_card = true;
	}

	setPaymentMethods(paymentMethods);
}


const App = () => {
	const [stage, setStage] = useState(2);
	const [inputValues, setinputValues] = useState({
		nombre: "",
		apellido: "",
		email: "",
		contactDni: "",
		amount: "",
		extraAmount: {},
		contactId: null,
		contactLink: '',
		groupId: null,
		terms: false,
		edit: false
	});
	const [isContactSubscribed, setIsContactSubscribed] = useState(false);
	const [metadata, setMetadata] = useState({});
	const [data, setData] = useState(null);
	const [plans, setPlans] = useState(null);
	const [contactHasSubscribedToThisPage, setContactHasSubscribedToThisPage] = useState(false);

	const [options, setOptions] = useState([]);
	const [isChecked, setIsChecked] = useState(false);
	const [productHash, setProductHash] = useState(null);
	const [invoice, setInvoice] = useState('');
	const [errorText, setErrorText] = useState("");
	const [paymentMethods, setPaymentMethods] = useState({});
	const [snackbar, setSnackbar] = useState({
		open: false,
		msg: ""
	});
	const [isPageExpired, setIsPageExpired] = useState(false);
	const [paymentExpiration, setPaymentExpiration] = useState(false);
	const [isPageDisabled, setIsPageDisabled] = useState(false);
	useEffect(() => {
		// TODO: Modulizar esta funcion:
		(async () => {
			const res = await personalizeStyle()
			setData(res);
			const params = new URLSearchParams(window.location.search);
			const hash = params.get('hash');
			let opt = null;

			if (plans == null) {
				if (res.plans) {
					//Si es hash de pagina, ya me trae los planes:
					opt = res.plans;
				} 
				if(hash){
					const response = await getUserPlans();
					opt = response.data;
				}
				if(params.get('phash')){
				
					try{
					const response = await getPlan();
					
					// esta mal diseñado todo este codigo
					// antes traia los planes en un array y ahi comparaba con el hash
					// para mantener compatibilidad y no tener que cambiar en todos lados 
					// la respuesta del back la cargamos en un array
					const arrayPlans = [];
					arrayPlans.push(response.data);
					opt = arrayPlans;
					}
					catch(error){
						setIsPageDisabled(true);
					}
				}
			
			} else {
				opt = plans;
			}
			setPaymentMethodsObject(res.paymentMethods, setPaymentMethods);
			handleInputChange(null, 'customFields', res.customFields);
			setPlans(opt); // All plans
			if (params.get('phash')) {
				setPlansOptions(opt, setOptions, 1, null, handleInputChange, params.get('phash'), setProductHash); // Filtered plans
				setIsChecked(true);
			} else {
				setPlansOptions(opt, setOptions, 1, null, handleInputChange); // Filtered plans
			}

			checkPageExpiry(res.pageExpirationDate);
			checkPaymentExpiry(res);
		})();
		checkContactHash();
	}, []);

	useEffect(() => {
		if (data != null)
			personalizeStyle(data);
	});

	const checkPageExpiry = expirationDate => {
		if (expirationDate) {
			const now = new Date();
			const expiry = new Date(expirationDate);

			setIsPageExpired(expiry < now);
		}
	}

	const convertDate = (inputFormat) => {
		function pad(s) { return (s < 10) ? '0' + s : s; }
		var d = new Date(inputFormat)
		return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
	}

	const checkPaymentExpiry = config => {
		const now = new Date();
		const {
			daysAfterFirstDue,
			daysAfterSecondDue,
			startDate,
			firstDuePercentage,
			secondDuePercentage
		} = config;

		const firstDate = moment(startDate)
			.add(daysAfterFirstDue, 'days')
			.startOf('day')
			.toDate()

		const secondDate = moment(startDate)
			.add(daysAfterFirstDue + daysAfterSecondDue, 'days')
			.startOf('day')
			.toDate()

		if (secondDate < now) {
			setPaymentExpiration({
				expired: true,
				date: convertDate(secondDate),
				percentage: secondDuePercentage
			});
			return;
		}

		if (firstDate < now) {
			setPaymentExpiration({
				expired: true,
				date: convertDate(firstDate),
				percentage: firstDuePercentage
			});
		}
	}

	const checkContactHash = async () => {
		const chash = params.get('chash');
		if (chash) {
			const contactInfo = await getContactInfo();
			if (contactInfo.data) {
				prefillForm(contactInfo.data);
				handleInputChange(null, 'contactId', contactInfo.data.id);
			}
		}
	}

	const prefillForm = obj => {
		let fname = null;
		let lname = null;
		const email = obj.emails[0] ? obj.emails[0].email : '';

		obj.firstName ?
			fname = obj.firstName.charAt(0).toUpperCase() + obj.firstName.slice(1).toLowerCase() : fname = null;

		obj.lastName ?
			lname = obj.lastName.charAt(0).toUpperCase() + obj.lastName.slice(1).toLowerCase() : lname = null;

		fname && handleInputChange(null, 'nombre', fname);
		lname && handleInputChange(null, 'apellido', lname);
		email && handleInputChange(null, 'email', email);
	}

	const handleInputChange = (event, name = 0, value = 0) => {


		if (name == 0) {
			event.persist();
			setinputValues(inputValues => ({ ...inputValues, [event.target.name]: event.target.value }));
			document.querySelector(`input[name="${event.target.name}"]`)
				.classList.remove("inputBarRed");
		} else {
			setinputValues(inputValues => ({ ...inputValues, [name]: value }));
			if (document.querySelector(`input[name="${name}"]`)) {
				document.querySelector(`input[name="${name}"]`)
					.classList.remove("inputBarRed");
			}
		}
	}

	const handleMetadataChange = (event, name = 0, value = 0) => {
		if (name == 0) {
			event.persist();
			setMetadata(metadata => ({ ...metadata, [event.target.name]: event.target.value }));
			document.querySelector(`input[name="${event.target.name}"]`)
				.classList.remove("inputBarRed");
		} else {
			setMetadata(metadata => ({ ...metadata, [name]: value }));
			if (document.querySelector(`input[name="${name}"]`)) {
				document.querySelector(`input[name="${name}"]`)
					.classList.remove("inputBarRed");
			}
		}
	}

	const handleExtraAmountChange = (key, value) => {
		const extraAmount = { ...inputValues.extraAmount };
		extraAmount[key] = value;
		handleInputChange(null, "extraAmount", extraAmount);
	}

	const handleContinueButtonClick = (isChecked = null, autoScroll = true) => {
		const offset = isChecked ? 320 : 420;
		if (checkPersonalForm(inputValues) || isChecked) {
			setStage(3);
			if (autoScroll)
				setTimeout(() => window.scrollTo(0, offset, 10));
		}
	}

	const irAlCheckout = async (isNgo, callback) => {
		if (inputValues.paymentMethod == undefined) {
			setSnackbar({
				open: true,
				msg: "Elige el medio de pago."
			})
			return;
		}

		if (checkPaymentForm(inputValues, setErrorText, metadata)) {
			
			if (inputValues.paymentMethod == "SEND_INVOICE" || 
			inputValues.paymentMethod == "AHORA12") {
				if (callback)
					callback();
				const ret = await sendCollectPaymentRequest(inputValues, metadata, data.orgHash, data.pageId);
				try {
					setInvoice(ret.data);
				} catch (e) {
					console.log({ e });
				}
				if (ret.status == 200) {
					setStage(7);
				} else {
					setSnackbar({
						open: true,
						msg: "Hubo un error al procesar tu pago."
					})
				}
			}

			if (inputValues.paymentMethod == "CHARGE") {
				setStage(4);
				setTimeout(() => window.scrollTo(0, 0, 10));
				return;
			}



		}
	}


	const acceptTerms = () => {
		handleInputChange(null, 'terms', !inputValues.terms)
	}


	const restorePlansData = (filterAhora12=true) => {
		const options = plans.filter(elem => {
			if(filterAhora12){
				if (elem.ahora12Enabled) {
					return false;
				}
			}
			return true;
	}).map(elem => {
			const value = {}
			const text = getPlanText(elem, data.template);
			value.price = elem.price;
			value.id = elem.id;
			return { value, text };
		});
		setOptions(options);
	}
	const filterAhora12Plans = () =>{
		const options = plans.filter(elem => {
				if (elem.ahora12Enabled) {
					return true;
				}
				return false;
		}).map(elem => {
			const value = {}
			const text = getPlanText(elem, data.template);
			value.price = elem.price;
			value.id = elem.id;
			return { value, text };
		});
		setOptions(options);
	}
	


	return (
		<div className="App">
			{
				snackbar.open &&
				<Snackbar
					text={snackbar.msg}
					onClick={() => {
						setSnackbar({
							open: false,
							msg: ""
						})
					}}
				/>
			}
			{
				isPageExpired && <ExpiryWall data={data} />
			}
			{
				isPageDisabled && <SubscriptionWall />
			}
			<Loader />
			<div id="leftPanel"></div>
			<div className="mainContent" >
				<DataContext.Provider value={{ inputValues, metadata, setinputValues, stage, setStage, paymentMethods }}>
					<div className={stage == 1 || stage >= 4 ? "mainForm" : "mainFormNoMargin"}>
						{
							stage < 4 &&
							<div>
								<h1 id="pageTitle"></h1>
								<h3 id="pageSubtitle"></h3>
								{
									productHash &&
									<h3 id="productDescription">{options[0] ? options[0].text : ""}</h3>
								}
								{
									!productHash && data && data.template == "NGO" &&
									<div className="simpleInput">
										<label className="simpleLabel">Realizá tu aporte</label>
										<label onClick={() => { setPlansOptions(plans, setOptions, 0, data.template, handleInputChange); setIsChecked(true); }} className="radioButtonContainer">Pago único
											<input type="radio" name="radio" />
											<span className="checkmark"></span>
										</label>
										<label onClick={() => { setPlansOptions(plans, setOptions, 1, data.template, handleInputChange); setIsChecked(true); }} className="radioButtonContainer">Mensualmente
											<input type="radio" name="radio" />
											<span className="checkmark"></span>
										</label>
									</div>
								}
								{
									stage >= 2 && (isChecked || (data && data.template != "NGO")) &&
									<PersonalDataForm
										organizationId={data.organizationId}
										contactGroup={data.contactGroup}
										handleInputChange={handleInputChange}
										handleContinueButtonClick={handleContinueButtonClick}
										setIsContactSubscribed={setIsContactSubscribed}
										stage={stage}
										setContactHasSubscribedToThisPage={setContactHasSubscribedToThisPage}
										data={data}
									/>
								}
								{
									stage == 3 && !contactHasSubscribedToThisPage &&
									<PaymentDataForm
										plans={plans}
										data={data}
										options={options}
										setOptions={setOptions}
										setPlansOptions={setPlansOptions}
										setProductHash={setProductHash}
										setIsChecked={setIsChecked}
										isChecked={isChecked}
										handleInputChange={handleInputChange}
										metadata={metadata}
										handleMetadataChange={handleMetadataChange}
										handleExtraAmountChange={handleExtraAmountChange}
										irAlCheckout={irAlCheckout}
										errorText={errorText}
										acceptTerms={acceptTerms}
										productHash={productHash}
										isDasMas={data.organizationId == 154}
										paymentExpiration={paymentExpiration}
										ahora12Enabled={data.ahora12Enable}
										restorePlansData={restorePlansData} 
										filterAhora12Plans={filterAhora12Plans}
									/>
								}
								{
									contactHasSubscribedToThisPage && stage == 3 &&
									<ContactWall nombre={inputValues.nombre} link={inputValues.contactLink} />
								}
							</div>
						}
						{
							stage >= 4 && stage < 7 &&
							<Checkout
								handleInputChange={handleInputChange}
								isNgo={data.template === "NGO"}
								organizationId={data.organizationId}
								orgHash={data.orgHash}
								pageId={data.pageId}
							/>
						}
						{stage >= 7 && <Success invoice={invoice} email={inputValues.email} paymentMethod={inputValues.paymentMethod}
						successMessagge={data.organizationId == 154?'Pedido generado con éxito':'Subscripción generada con éxito'}
						/>}
					</div>
					<Footer />
				</DataContext.Provider>
			</div>
		</div>
	);
}


export default App;