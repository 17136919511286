import React from 'react';
import SimpleButton from './SimpleButton.js';
import '../App.css';

const SubscriptionWall = props => {
	const { nombre, link } = props;

	return (
		<div className="expiryWallContainer">
			<h1>Hola</h1>
            Este es un link no valido para realizar la suscripción, contactate con la persona que te compartio el link.
		</div>
	);
}

export default SubscriptionWall;