const params = new URLSearchParams(window.location.search);
const hash = params.get('hash');

export const paymentDto = (inputValues, tokenDto, paymentValues, metadata, pageId) => {
    return {
        "subscriptions": [
            {
                "contact": {
                    "id": inputValues.contactId,
                    "email": inputValues.email,
                    "firstName": inputValues.nombre,
                    "lastName": inputValues.apellido,
                    "holderDocumentNumber": inputValues.contactDni,
                    "phoneAreaCode": inputValues.areaCode,
                    "phoneNumber": inputValues.phoneNumber,
                    "externalReference": inputValues.contactDni,
                    "edit": inputValues.edit
                },
                "groupId": inputValues.groupId,
                "startDate": currentDate(),
                "paymentToken": {
                    "bin": tokenDto.bin,
                    "token": tokenDto.token,
                    "lastFourDigits": tokenDto.lastFourDigits
                },
                "customFieldsData": mapMetadata(metadata),
                "pageId": pageId,
                "amount": inputValues.amount,
                "intervalCount": 1,
                "interval": "mensual",
                "periods": inputValues.planId ? null : inputValues.recurrencia,
                "planId": inputValues.planId,
                "collectType": "charge",
                "creditCard": paymentValues.isCredit
            }
        ]
    }
}


export const collectPaymentDto = (inputValues, metadata, pageId) => {
    return {
        "subscriptions": [
            {
                "contact": {
                    "id": inputValues.contactId,
                    "email": inputValues.email,
                    "firstName": inputValues.nombre,
                    "lastName": inputValues.apellido,
                    "holderDocumentNumber": inputValues.contactDni,
                    "phoneAreaCode": inputValues.areaCode,
                    "phoneNumber": inputValues.phoneNumber,
                    "externalReference": inputValues.contactDni,
                    "edit": inputValues.edit
                },
                "groupId": inputValues.groupId,
                "startDate": currentDate(),
                "paymentToken": {
                    "bin": null,
                    "token": null,
                    "lastFourDigits": null
                },
                "customFieldsData": mapMetadata(metadata),
                "pageId": pageId,
                "amount": inputValues.amount,
                "intervalCount": 1,
                "interval": "mensual",
                "periods": inputValues.planId ? null : inputValues.recurrencia,
                "planId": inputValues.planId,
                "collectType": "sendInvoice",
                "creditCard": false
            }
        ]
    }
}


const mapMetadata = metadata => {
    const metadataArr = [];
    for(const [key, value] of Object.entries(metadata)) {
        if(hash) {
            const obj = { customFieldId: key, value };
            metadataArr.push(obj);
        } else {
            const obj = { customFieldInstanceId: key, value };
            metadataArr.push(obj);
        }
    }
    return metadataArr;
}


const currentDate = () => {
    let d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if(month.length < 2) month = '0' + month;
    if(day.length < 2) day = '0' + day;


    return [year, month, day].join('-');
}