import React, { useState } from 'react';
import '../App.css';

const getClassString = label => {
	if(label == "Inicial" || label == "Número") {
		return "miniInput";
	}

	return "";
}



const SimpleInput = props => {
	const {
		labelText,
		extraText,
		placeholderText,
		onChange,
		name,
		type,
		value,
		extraPriceValue,
		organizationId,
		handleExtraAmountChange
	} = props;
	const classString = getClassString(labelText);
	const [extraPrice, setExtraPrice] = useState(0);
	const [isEnabled, setIsEnabled] = useState(true);

	const onChangeEvent = e => {
		if(isEnabled) {
			changeExtraValue(e);
			onChange(e);
		}
	}

	const disableField = () => {
		setIsEnabled(false);
		onChange(null, name, '');
		handleExtraAmountChange('Apodo', 0);
		setExtraPrice(0);
	}

	const changeExtraValue = e => {
		if(labelText == "Apodo" && organizationId == 154) {
			if(e.target.value && e.target.value.replace(/\s/g, '').length > 5) {
				const total = extraPriceValue * (e.target.value.replace(/\s/g, '').length - 5);
				handleExtraAmountChange(labelText, total);
				setExtraPrice(total);
			} else {
				handleExtraAmountChange(labelText, 0);
				setExtraPrice(0);
			}
		} else {
			if(handleExtraAmountChange) {
				if(e.target.value && e.target.value.length > 0) {
					handleExtraAmountChange(labelText, extraPriceValue);
					setExtraPrice(extraPriceValue);
				} else {
					handleExtraAmountChange(labelText, 0);
					setExtraPrice(0);
				}
			}
		}
	}


	return (
		<div className={"simpleInput " + classString}>
			<label className="simpleLabel">{labelText}</label>
			{
				extraText &&
				<p className="extraText">{extraText}</p>
			}
			{
				labelText == "Apodo" && organizationId == 154 &&
				<div className="inputCheckbox">
					<input
						checked={isEnabled}
						onChange={e => { !isEnabled ? setIsEnabled(true) : disableField(e) }}
						type="checkbox">
					</input>
					<p>
						Usar apodo
                    </p>
				</div>
			}
			<input
				type={type}
				name={name}
				value={value}
				onChange={e => onChangeEvent(e)}
				placeholder={placeholderText}
				className={isEnabled ? 'inputBar' : 'disabledInputBar'}
			>
			</input>
			{
				extraPrice > 0 && <p className="msgExtra">{`Suma $${extraPrice} al precio total`}</p>
			}
		</div>
	);
}

export default SimpleInput;