import React, { useState, useContext, useEffect } from 'react';
import { DataContext } from '../commons/DataContext.js';
import { sendPaymentRequest } from '../commons/Services.js';
import Cards from './ReactCard/index';
import SimpleInput from './SimpleInput.js';
import CardPersonalInfo from './CardPersonalInfo.js';
import SimpleButton from './SimpleButton.js';
import DropdownInput from './OptionButtons.js';
import ExpirationInput from './ExpirationInput.js';
import CardHolderData from './CardHolderData.js';
import Snackbar from './Snackbar.js';
import './ReactCard/styles-compiled.css';


const checkIfDateExpired = expire => {
    const d = new Date();
    const cYear = d.getFullYear();
    const cMonth = d.getMonth() + 1;
    const parts = expire.split('/');
    const year = parseInt(parts[1], 10) + 2000;
    const month = parseInt(parts[0], 10);
    if (year < cYear || (year == cYear && month < cMonth)) {
        return false;
    }
    return true;
}


const checkInputValues = (paymentValues, isValid) => {
    let result = true;
    const wrongFormat = !paymentValues.expiry.match(/(0[1-9]|1[0-2])['/'][0-9]{2}/);
    const isExpired = !checkIfDateExpired(paymentValues.expiry);

    if (wrongFormat || isExpired) {
        result = false;
        document.querySelector('input[name="expiry"]').classList.add("inputBarRed");
    }
    if (!isValid) {
        result = false;
        document.querySelector('input[name="number"]').classList.add("inputBarRed");
    }

    return result;
}


const getTotalAmount = inputValues => {
    if (Object.values(inputValues.extraAmount) && Object.values(inputValues.extraAmount).length) {
        const reducer = (accumulator, currentValue) => accumulator + currentValue;
        const extraPrice = Object.values(inputValues.extraAmount).reduce(reducer);
        return extraPrice + inputValues.amount;
    }
    return inputValues.amount;
}


const Checkout = props => {
    const { inputValues, metadata, stage, setStage, paymentMethods } = useContext(DataContext);
    const { isNgo, organizationId, orgHash, pageId } = props;
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCreditCardValid, setIsValid] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        msg: ""
    });
    const [paymentValues, setPaymentValues] = useState({
        cvc: "",
        expiry: "",
        number: "",
        isCredit: true,
        dni: inputValues.dni,
        fname: inputValues.nombre,
        lname: inputValues.apellido
    });
    const options = [];
    if (paymentMethods.credit_card) {
        options.push({ value: 1, text: "Crédito" })
    }
    if (paymentMethods.debit_card) {
        options.push({ value: 0, text: "Débito" })
    }

    useEffect(() => {
        if (organizationId == 154)
            setIsEditing(true);
    }, [])

    const handleInputChange = (event, name = 0, value = 0) => {
        if (name == 0) {
            event.persist();
            setPaymentValues(paymentValues => ({ ...paymentValues, [event.target.name]: event.target.value }));
            document.querySelector(`input[name="${event.target.name}"]`)
                .classList.remove("inputBarRed");
        } else {
            setPaymentValues(paymentValues => ({ ...paymentValues, [name]: value }));
            const elem = document.querySelector(`input[name="${name}"]`);
            if (elem) elem.classList.remove("inputBarRed");
        }
    }

    const handleCardChange = e => {
        if (e.target.value.length > 23)
            e.target.value = e.target.value.substr(0, 23);

        e.target.value = e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
        handleInputChange(null, "number", e.target.value);
    }

    const handleExpiryChange = e => {
        let str = e.target.value;
        if (str.length > 5)
            str = str.substr(0, 5);

        str = str.replace(/[^\dA-Z]/g, '');
        if (str.length > 2)
            str = str.substring(0, 2) + '/' + str.substring(2, str.length);

        e.target.value = str;
        handleInputChange(null, "expiry", str);
    }

    const handleSendRequest = async () => {
        const isInputValid = checkInputValues(paymentValues, isCreditCardValid);
        if (isInputValid) {
            setIsLoading(true);
            const ret = await sendPaymentRequest(paymentValues, inputValues, metadata, orgHash, pageId);
            if (ret.status == 200) {
                setStage(7);
            } else {
                setIsLoading(false);
                setSnackbar({
                    open: true,
                    msg: "Hubo un error al procesar tu pago, puedes probar con otra tarjeta o revisar los datos y reintentar con la misma."
                })
            }
        }
    }

    const validateCardNumber = (_type, isValid) => {
        setIsValid(isValid);
    }


    return (
        <div>
            {
                snackbar.open &&
                <Snackbar
                    text={snackbar.msg}
                    onClick={() => {
                        setSnackbar({
                            open: false,
                            msg: ""
                        })
                    }}
                />
            }
            <div id="PaymentForm">
                {
                    isLoading &&
                    <div className="checkoutLoaderContainer">
                        <svg viewBox="0 0 32 32" width="124" height="124">
                            <circle id="spinner" cx="16" cy="16" r="14" fill="none"></circle>
                        </svg>
                        Procesando pago...
                    </div>
                }
                <button onClick={() => setStage(3)} className="goBackButton">
                    <div className="goBackIcon"></div>
                    Volver al paso anterior
                </button>
                <h1>Checkout</h1>
                <Cards
                    cvc={paymentValues.cvc}
                    expiry={paymentValues.expiry}
                    name={inputValues.nombre + " " + inputValues.apellido}
                    number={paymentValues.number}
                    callback={validateCardNumber}
                />
                <DropdownInput
                    labelText={"Tipo de tarjeta"}
                    name={"cardType"}
                    onChange={value => {
                        if (value == 1) {
                            handleInputChange(null, "isCredit", 1);
                            setStage(5);
                            return;
                        }
                        setStage(6);
                        handleInputChange(null, "isCredit", 0);
                    }}
                    options={options}
                />
                {
                    stage >= 5 &&
                    <div>
                        <SimpleInput
                            type="text"
                            labelText={"Número de tarjeta"}
                            name={"number"}
                            placeholderText={"XXXX XXXX XXXX XXXX"}
                            onChange={handleCardChange}
                        />
                        <ExpirationInput
                            labelText={"Fecha de vencimiento"}
                            anio={false}
                            setCvc={handleInputChange}
                            handleDateChange={handleExpiryChange}
                            isNgo={isNgo}
                        />
                        {
                            isEditing ?
                                <CardHolderData
                                    fname={paymentValues.fname}
                                    lname={paymentValues.lname}
                                    dni={paymentValues.dni}
                                    handleInputChange={handleInputChange}
                                /> :
                                <CardPersonalInfo
                                    onClick={setIsEditing}
                                    labelText={"Titular"}
                                    fname={inputValues.nombre}
                                    lname={inputValues.apellido}
                                />
                        }
                        <div className="paymentClarificationDiv">
                            <span className="paymentClarificationSpan">i</span>
                            <p className="paymentClarification">El total se cobrará automaticamente a ésta tarjeta durante todas las cuotas.</p>
                        </div>
                        <div className="totalDiv">
                            <label className="labelTotalAmount">Total:</label>
                            <p className="totalAmount">{'$' + getTotalAmount(inputValues)}</p>
                        </div>
                        <SimpleButton
                            buttonText={"Finalizar pago"}
                            onClick={handleSendRequest}
                        />
                    </div>
                }
            </div>
        </div>
    );
}

export default Checkout;