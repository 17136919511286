import React from 'react';
import '../App.css';

const PhoneInput = props => {
    const { labelText, handleInputChange } = props;

    const handleChange = (value, name) => {
        handleInputChange(null, name, value);
    }

    return (
        <div>
            <div className="expirationInput">
                <label className="simpleLabel">{labelText}</label>
                <p className="phoneFormat">+54 9 </p>
                <input
                    type="number"
                    name="areaCode"
                    onChange={e => handleChange(e.target.value, 'areaCode')}
                    placeholder="011"
                    className="shortInputBar"
                >
                </input>
                <p className="phoneFormat"> - </p>
                <input
                    type="number"
                    name="phoneNumber"
                    onChange={e => handleChange(e.target.value, 'phoneNumber')}
                    placeholder="654321"
                    className="mediumInputBar"
                >
                </input>
            </div>
        </div>
    );
}

export default PhoneInput;